.custom-radio {
  &-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $gray900;
    margin-bottom: 8px;
  }
  &-wrap {
    display: flex;
    align-items: center;
    gap: 8px;
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 10px;

      background-color: $gray200;
      border: 1px solid $gray200;
      border-radius: 4px;
      cursor: pointer;

      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $gray800;
      position: relative;
      input {
        width: 20px;
        height: 20px;
        border: 2px solid $gray400;
        background-color: $gray200;
        cursor: pointer;
        &:focus {
          box-shadow: none;
        }
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .active {
      background-color: $white;
      border-color: $primary700;
      color: $primary700;
      input {
        position: relative;
        background-color: $primary700;
        background-size: 200% 200%;
        &:after {
          background-color: $primary700;
        }
      }
    }
  }
}
