.scrollbar-hidden {
  overflow: hidden; /* Hide scrollbars */
}

.custom-drower {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 999;
  overflow-y: hidden;
  overflow-x: hidden;

  pointer-events: none;
  &-card {
    &-top {
      width: 100%;
    }
    &-right {
      height: 100vh;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1000;
      overflow-y: auto;
      transition: $transition;
      &-840 {
        right: -840px;
      }
      &-662 {
        right: -662px;
      }
    }
    &-bottom {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
    }
    &-left {
      height: 100vh;
    }
  }
  &-header {
    padding: 24px;
    border-bottom: 1px solid $gray200;
    position: relative;
    h5 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.01em;
      color: $gray900;
    }
  }
  &-content {
    padding: 24px;
    border-bottom: 1px solid $gray200;
  }
  &-footer {
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &-blured {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(15px);
    pointer-events: initial;
  }
  &-visible {
    pointer-events: initial;
  }
}

// @media (max-width: 840px) {
//   .custom-drower-card {
//     &-right {
//       width: 100% !important;
//     }
//   }
// }

@media (max-width: 680px) {
  .custom-drower-card {
    &-right {
      width: 100% !important;
    }
  }
}
