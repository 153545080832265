.custom-button {
  outline: none;
  button {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: 0.3s;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $gray800;

    border-radius: 4px;
    border: 1px solid transparent;
    outline: 2px solid transparent;
    svg {
      width: 24px;
      height: 24px;
      transition: 0.3s;
      path {
        transition: 0.3s;
      }
    }
  }

  //Size options
  .xs {
    padding: 4px 12px;
    font-size: 12px;
    line-height: 16px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .sm {
    padding: 6px 12px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .l {
    padding: 12px 16px;
    font-size: 16px;
  }
  .xl {
    padding: 16px 24px;
    font-size: 16px;
  }

  //Color options
  .glass,
  .white {
    border: none;
    outline: none;
    &:hover,
    &:focus {
      border: none;
      outline: none;
    }
  }
  .gray {
    color: $gray800;
    background-color: $gray200;
    border: 1px solid $gray200;
    svg path {
      fill: $gray800;
    }
    &:hover {
      background-color: $gray300;
      border-color: $gray100;
    }
    &:focus {
      background-color: $gray300;
      border-color: $gray100;
      outline: 2px solid $gray100;
    }
  }
  .red {
    color: $white;
    background-color: $red800;
    border: 1px solid $red800;
    svg path {
      fill: $red800;
    }
    &:hover {
      border-color: $red200;
      background-color: $red800;
    }
    &:focus {
      border-color: $red200;
      background-color: $red800;
      outline: 2px solid $red200;
    }
  }
  .glass {
    color: $white;
    background-color: $glass;
    svg path {
      fill: $white;
    }
    &:hover {
      background-color: $glass_hover;
    }
    &:focus {
      background-color: $glass_active;
    }
  }
  .alternative,
  .menu {
    background-color: transparent;
    border-color: transparent;
    &:hover {
      background-color: $gray100;
      border-color: $gray200;
    }
    &:focus {
      background-color: $gray100;
      border-color: $gray200;
    }
  }
  .menu {
    outline: none;
    svg path {
      fill: $gray800;
    }
    &:focus,
    &:hover {
      outline: none;
      color: $primary600;
      svg path {
        fill: $primary600;
      }
    }
  }
  .dark {
    background-color: transparent;
    border: none;
    &:hover {
      border: none;
      background-color: $gray700;
      color: $white;
      svg path {
        fill: $white;
      }
    }
    &:focus {
      border: none;
      background-color: $gray700;
      outline: 3px solid $gray200;
      color: $white;
      svg path {
        fill: $white;
      }
    }
  }
  .white {
    background-color: transparent;
    color: $white;
    svg path {
      fill: $white;
    }
    &:hover {
      background-color: $white;
      color: $primary600;
      svg path {
        fill: $primary600;
      }
    }
    &:focus {
      background-color: $white;
      color: $primary600;
      svg path {
        fill: $primary600;
      }
    }
  }
  .primary {
    background-color: $primary700;
    color: $white;
    border-color: $primary700;
    svg path {
      fill: $white;
    }
    &:hover {
      background-color: $primary800;
      color: $white;
      svg path {
        fill: $white;
      }
    }
    &:focus {
      background-color: $primary800;
      color: $white;
      outline: 2px solid $blue200;
      svg path {
        fill: $white;
      }
    }
  }
  .green {
    background-color: $white;
    color: $green700;
    border-color: $green700;
    svg path {
      fill: $green700;
    }
    &:hover {
      background-color: $green800;
      color: $white;
      svg path {
        fill: $white;
      }
    }
    &:focus {
      background-color: $green800;
      color: $white;
      outline: 2px solid $green200;
      svg path {
        fill: $white;
      }
    }
  }
  .oneid {
    background-color: $white;
    color: $oneid;
    border-color: $oneid;
    svg {
      width: 77px;
    }
    &:hover {
      background-color: $oneid;
      color: $white;
    }
    &:focus {
      background-color: $oneid;
      color: $white;
      outline: 2px solid $oneid_outline;
    }
  }
  .info {
    color: $primary800;
    border-color: none;
    background-color: $primary100;
    svg path {
      fill: $primary800;
    }
  }
  .error {
    color: $red800;
    border-color: none;
    background-color: $red100;
    svg path {
      fill: $red800;
    }
  }
  .success {
    color: $green800;
    border-color: none;
    background-color: $green100;
    svg path {
      fill: $primary800;
    }
  }
}
.outline {
  button {
    color: $gray800;
    background-color: $white;
    border: 1px solid $gray200;
  }
  .primary {
    background-color: $white;
    color: $primary700;
    border-color: $primary700;
    svg path {
      fill: $primary700;
    }
  }
  .red {
    color: $red700;
    background-color: $white;
    border-color: $red700;
    svg path {
      fill: $red700;
    }
    &:hover {
      color: $red700;
      background-color: $white;
      border-color: $red700;
    }
    &:focus {
      color: $red700;
      background-color: $white;
      border-color: $red700;
    }
  }
  .dark {
    border: none;
    background-color: $gray700;
    color: $white;
    svg path {
      fill: $white;
    }
  }
  .green {
    background-color: $green800;
    color: $white;
    svg path {
      fill: $white;
    }
  }
}
.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.searchMiniInput {
  gap: 8px;
  display: flex;
  cursor: pointer;
  padding: 4px 12px;
  align-items: center;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  background: rgba(255, 255, 255, 0.2);
  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #ffffff;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
  &:active {
    background: rgba(255, 255, 255, 0.3);
  }
}
.customBtn {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  &-active {
    color: #1a56db;
    background: #e1effe;
  }
}
.customRightArrowBtn {
  padding: 6px 12px;
  border: 1px solid #1a56db;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1a56db;
}
.sidebarBtn {
  margin-right: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  gap: 8px;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  color: $gray400;
  svg {
    flex-shrink: 0;
    path {
      transition: 0.3s ease-in-out;
    }
  }

  &:hover {
    color: $gray800;
    background: #ebf5ff;
    svg path {
      fill: $gray800;
    }
  }
  &__active {
    color: #1c64f2;
    background: #ebf5ff;
    svg path {
      fill: #1c64f2;
    }
    &:hover {
      color: #1c64f2;
      svg path {
        fill: #1c64f2;
      }
    }
  }
}
.customTable {
  margin-top: 24px;
  padding: 16px;
  display: block;
  border-radius: 8px;
  background: #ffffff;
  border-collapse: collapse;
  box-shadow: 0px 1px 4px #e3ebfc, 0px 24px 48px rgba(230, 235, 245, 0.4);

  td,
  th {
    padding: 12px;
    border: 1px solid #f3f4f6;
  }

  th {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #9ca3af;
    border: none;
    text-align: left;

    &:nth-child(1) {
      width: 280px;
    }
    &:nth-child(3) {
      width: 250px;
    }
  }
  td {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #251a31;
    vertical-align: top;
  }
}
.customAlert {
  padding: 16px;
  margin-bottom: 24px;
  background: $blue50;
  border-radius: 8px;
  border: 1px solid $blue700;
  transition: opacity 1s ease-out;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__left {
    gap: 8px;
    display: flex;
    align-items: center;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $blue700;
    }
  }
  &__body {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $blue700;
    letter-spacing: -0.12px;
  }
  svg {
    cursor: pointer;
    flex-shrink: 0;
  }

  // Color changes
  &__yellow {
    background: $yellow50;
    border: 1px solid $yellow300;
    span,
    p {
      color: $yellow400;
    }
    svg path {
      fill: $yellow400;
    }
  }
  &__red {
    background: $red100;
    border: 1px solid $red700;
    span,
    p {
      color: $red700;
    }
    svg path {
      fill: $red700;
    }
  }
  &__green {
    background: $green100;
    border: 1px solid $green700;
    span,
    p {
      color: $green700;
    }
    svg path {
      fill: $green700;
    }
  }
}
