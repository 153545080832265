// Home
.home {
  background-color: #fafcff;

  //section
  section {
    margin-top: 72px;
    & > .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 40px;
      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.01em;
        color: #251a31;
      }
    }
  }
  &-top {
    padding-top: 96px;
    background: linear-gradient(180deg, #2d2db0 -28.98%, #2c8cf4 106.82%);
    &-info {
      width: 30rem;
    }
    &__category {
      gap: 56px;
      margin-top: 64px;
      justify-content: center;
      &-item {
        div {
          width: 96px;
          height: 96px;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: linear-gradient(
            0deg,
            rgba(187, 186, 186, 0.7) 0%,
            rgba(255, 255, 255, 0.2) 100%
          );
          margin-bottom: 12px;
          transition: 0.3s ease-in-out;
        }
        button {
          width: 94px;
          height: 94px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #4b83d6;
          border-radius: 50%;
        }
        h4 {
          width: 108px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
        }
        &:hover {
          div {
            transform: translateY(-8px);
          }
        }
      }
      &-active {
        div {
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.7) 0%,
            rgba(255, 255, 255, 0.2) 100%
          );
        }
        button {
          background-color: #6197ea;
        }
      }
      &__divider {
        width: 1px;
        flex: none;
        margin: 0px -16px;
        height: 100px;
        background: radial-gradient(
          circle,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0.2) 100%
        );
      }
    }
    h1 {
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
      text-align: center;
      color: #ffffff;
    }
  }
  &__search {
    width: 60%;
    margin: auto;
    input {
      padding: 17px 40px;
      border-radius: 4px;
      &::placeholder {
        font-size: 16px;
        line-height: 24px;
        color: #9ca3af;
      }
    }
  }
  &__notifications {
    &_tab {
      .flex {
        gap: 12px;
        display: flex;
        align-items: center;
      }
    }
    &_body {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 60%;
      margin: 80px auto auto;
      text-align: center;
      &_title {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #6b7280;
        margin-top: 24px;
      }
      &_info {
        margin: 12px 0 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #6b7280;
        span {
          color: black;
          font-weight: 600;
        }
      }
      .rightLongArrow {
        position: absolute;
        right: -120px;
        top: 32px;
      }
    }
  }
  &__applications {
    gap: 32px;
    display: flex;
    align-items: flex-start;
    &-list {
      flex-grow: 1;
      .applicationCard {
        &:first-child {
          margin-top: 0;
        }
      }
    }

    .customAlert {
      width: 360px;
    }
  }
  &__services {
    margin-top: 56px !important;
    &-list {
      gap: 32px;
      flex-wrap: wrap;
      padding-bottom: 0 !important;
    }
    &-item {
      gap: 16px;
      flex-grow: 1;
      display: flex;
      padding: 16px;
      cursor: inherit;
      flex-basis: 45%;
      align-items: center;
      border-radius: 12px;
      background: #ffffff;
      transition: 0.3s ease-in-out;
      box-shadow: 0px 1px 4px #e3ebfc, 0px 24px 48px rgba(230, 235, 245, 0.4);
      div {
        &:nth-child(1) {
          flex-shrink: 0;
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          border-radius: 24px;
          justify-content: center;
          svg {
            width: 40px;
            height: 40px;
          }
        }
        &:nth-child(2) {
          flex-grow: 1;
        }
      }
      & > svg {
        flex-shrink: 0;
      }
      h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #6b7280;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #6b7280;
      }
    }
    &-active {
      cursor: pointer;
      h3 {
        color: #251a31;
      }
      &:hover {
        box-shadow: 0px 12px 32px rgba(11, 31, 51, 0.18);
      }
    }
  }
  &__slider {
    margin: 72px 0;
    &-link {
      display: flex !important;
      justify-content: space-between;
      border-radius: 12px;
      padding: 16px;
      width: 94% !important;
      margin: auto;
      div {
        width: 200px;
        display: flex;
        flex-direction: column;
      }
      h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
      }
      p {
        margin: 4px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        flex-grow: 1;
      }
      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        width: fit-content;
        padding: 2px 6px;
      }
    }
    .yellow {
      background: radial-gradient(60.9% 111.77% at 97.79% 126.05%, #ffce6d 0%, #f2821b 100%);
    }
    .pink {
      background: linear-gradient(283.76deg, #757bff 4.72%, #6a47ff 84.28%);
    }
    .blue {
      background: linear-gradient(102.68deg, #0c88e6 12.24%, #69befe 97.02%);
    }
    .sky {
      background: radial-gradient(59.51% 120.26% at 82.81% 82.89%, #35c4fe 0%, #0090d5 100%);
    }
    .green {
      background: radial-gradient(57.16% 115.53% at 80.47% 77.89%, #10ca9e 0%, #1e967f 100%);
    }
    .slick-arrow {
      width: 40px;
      height: 40px;
      z-index: 2;
    }
    .slick-prev {
      left: -48px;
    }
    .slick-next {
      right: -48px;
    }
  }
  &__banner {
    overflow: hidden;
    position: relative;
    border-radius: 12px;
    padding: 80px 120px;
    background: radial-gradient(82.53% 227.5% at 62.95% 156.82%, #2c8cf4 0%, #2d2db0 100%);
    &-img {
      bottom: -28px;
      right: 60px;
      position: absolute;
      transition: 0.3s ease-in-out;
    }
    & > div {
      width: 60%;
      h2 {
        width: 70%;
        font-size: 32px;
        font-weight: 700;
        color: #ffffff;
        line-height: 40px;
        margin-bottom: 40px;
      }
      .flex {
        gap: 32px;
        align-items: center;

        p {
          width: 86%;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 16px;
        }
        .flex {
          gap: 16px;
        }
      }
    }
    &:hover {
      .home__banner-img {
        bottom: 0;
      }
    }
  }
  &__integrations {
    &-list {
      gap: 32px;
      flex-wrap: wrap;
      padding-bottom: 0 !important;
      div {
        display: flex;
        align-items: center;
        justify-content: center;

        flex-grow: 1;
        height: 132px;
        flex-basis: 20%;
        cursor: pointer;
        border-radius: 12px;
        background: #ffffff;
        transition: 0.3s ease-in-out;
        box-shadow: 0px 1px 4px #e3ebfc, 0px 24px 48px rgba(230, 235, 245, 0.4);
        &:hover {
          box-shadow: 0px 12px 32px rgba(11, 31, 51, 0.18);
        }
      }
    }
  }
  &__links {
    padding-bottom: 80px;
    &-list {
      gap: 32px;
      .news {
        flex-grow: 1;
        flex-shrink: initial !important;
      }
    }
  }
  .mobileMoreBtn {
    display: none;
  }
  .moreBtn {
    flex-shrink: 0;
  }
}
.news {
  width: 380px;
  display: block !important;
  img {
    object-fit: cover;
    transition: 0.3s ease-in-out;
  }
  h4 {
    font-size: 16px;
    font-weight: 700;
    color: #251a31;
    line-height: 24px;
    margin: 16px 0 18px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .flex {
    gap: 6px;
    align-items: center;
    b {
      width: 2px;
      height: 2px;
      display: block;
      background: #6b7280;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7280;
    }
    div {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7280;
      padding: 2px 8px;
      background: #e8edf1;
      border-radius: 4px;
    }
  }
  &__img {
    height: 216px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 16px;
    img {
      width: 100%;
    }
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}
.archive {
  padding: 48px 0 100px;

  .flex {
    gap: 24px;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
  }
  .right {
    width: 384px;
  }
  .left {
    flex-grow: 1;
  }
  .applicationCard {
    &:first-child {
      margin-top: 0;
    }
  }
}

@media (max-width: 1280px) {
  .home {
    overflow-x: hidden;
    &-top__category {
      overflow-x: scroll;
      justify-content: flex-start;

      scrollbar-width: thin;
      scrollbar-color: #7f9ba9 #dfe9eb;

      /* Chrome, Edge and Safari */
      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        display: none;
      }
      &::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: #dfe9eb;
      }

      &::-webkit-scrollbar-track:hover {
        background-color: #b8c0c2;
      }

      &::-webkit-scrollbar-track:active {
        background-color: #b8c0c2;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #7f9ba9;
      }
    }
    &__slider {
      .slick-prev {
        left: -24px;
      }
      .slick-next {
        right: -24px;
      }
    }
    &__links-list {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        display: none;
      }
    }
  }

  .footer {
    &__nav {
      gap: 36px;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 768px) {
  .home {
    &__search {
      width: 100%;
    }
    &-top {
      min-height: 444px;
      &__category {
        display: none;
      }
      &__searchs {
        flex-wrap: wrap;
        padding-bottom: 40px;
        justify-content: flex-start;
      }
    }
    &__applications {
      .customAlert {
        display: none;
      }
    }
    &__services {
      &-list {
        flex-direction: column;
      }
      &-item {
        width: 100%;
      }
    }
    &__banner {
      display: none;
    }
    &__integrations {
      &-list div {
        flex-basis: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .home-top-info {
    width: auto;
  }
  .home__notifications_body {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .home {
    section {
      & > .flex {
        padding-bottom: 24px;
      }
    }
    .moreBtn {
      display: none;
    }
    .mobileMoreBtn {
      margin: auto;
      display: flex;
    }
    &-top {
      h1 {
        font-size: 32px;
        line-height: 40px;
      }
      .w-96 {
        width: auto;
      }
      .mb-8 {
        margin-bottom: 16px;
      }
    }
    &__notifications {
      &_tab {
        & > a {
          display: none;
        }
      }
    }
    &__applications {
      .mobileMoreBtn {
        margin-top: 32px;
      }
    }
    &__slider {
      &-link {
        width: 100% !important;
        & > svg {
          width: 120px;
        }
      }
    }
    &__services-list {
      margin-bottom: 36px;
    }
    &__slider {
      margin: 24px 0;
    }
    &__integrations {
      display: none;
    }
  }
  .news {
    min-width: 100%;
  }
}
