// Variables colors
$black: rgb(0 0 0);
$white: rgb(255 255 255);
$glass: rgba(255, 255, 255, 0.2);
$glass_hover: rgba(255, 255, 255, 0.25);
$glass_active: rgba(255, 255, 255, 0.3);
$shadow: #e3ebfc;
$transition: 0.3s;
$link: #1890ff;
$warning: #faad14;
$success: #52c41a;
$error: #ff4d4f;
$oneid: #4825c2;
$oneid_outline: #4825c28a;
$car_shadow: 0px 1px 4px #e3ebfc, 0px 24px 48px rgba(230, 235, 245, 0.4);

//Slate
$slate50: rgb(248 250 252);
$slate100: rgb(241 245 249);
$slate200: rgb(226 232 240);
$slate300: rgb(203 213 225);
$slate400: rgb(148 163 184);
$slate500: rgb(100 116 139);
$slate600: rgb(71 85 105);
$slate700: rgb(51 65 85);
$slate800: rgb(30 41 59);
$slate900: rgb(15 23 42);
$slate950: rgb(2 6 23);

//Gray
$gray50: rgb(249 250 251);
$gray100: rgb(243 244 246);
$gray200: rgb(229 231 235);
$gray300: rgb(209 213 219);
$gray400: rgb(156 163 175);
$gray500: rgb(107 114 128);
$gray600: rgb(75 85 99);
$gray700: rgb(55 65 81);
$gray800: rgb(31, 41, 55);
$gray900: rgb(17, 24, 39);
$gray950: rgb(3 7 18);

//Blue
$blue50: #ebf5ff;
$blue200: #c3ddfd;
$blue700: #1a56db;

//Green
$green100: #def7ec;
$green200: #bcf0da;
$green700: #046c4e;
$green800: #03543f;

//Primary
$primary100: #e1effe;
$primary600: #1c64f2;
$primary700: #1a56db;
$primary800: #1e429f;

//Yellow
$yellow50: #fdfdea;
$yellow300: #faca15;
$yellow400: #e3a008;

//Red
$red50: #fdf2f2;
$red100: #fde8e8;
$red200: #fbd5d5;
$red700: #c81e1e;
$red800: #9b1c1c;

//Pink
$pink700: #bf125d;

//Purple
$purple700: #6c2bd9;
