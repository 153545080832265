.profile {
  background-color: #fafcff;
  .container {
    padding: 40px 0 80px;
    & > h1 {
      font-size: 32px;
      font-weight: 700;
      color: #251a31;
      line-height: 40px;
      margin-bottom: 40px;
    }
    & > .flex {
      align-items: flex-start;
    }
  }
  &__sidebar {
    width: 280px;
    flex-shrink: 0;
    margin-right: 16px;
    padding-right: 16px;
    box-sizing: content-box;
    border-right: 1px solid #e5e7eb;

    .divider {
      height: 1px;
      width: 100%;
      margin: 28px 0;
      background: #e5e7eb;
    }
  }
  &__navmobile {
    display: none;
  }
  &__name {
    gap: 12px;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    margin-bottom: 20px;

    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 1px 4px #e3ebfc, 0px 24px 48px rgba(230, 235, 245, 0.4);

    div {
      &:nth-child(1) {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(80.91deg, #e13e3b 0%, #e87e09 100%);
        border-radius: 999px;

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      &:nth-child(2) {
        h2 {
          font-size: 14px;
          font-weight: 700;
          color: #251a31;
          line-height: 24px;
        }
        h3 {
          font-size: 12px;
          font-weight: 500;
          color: #6b7280;
          line-height: 16px;
        }
      }
    }
  }
  &__body {
    flex-grow: 1;
  }
  &__info {
    gap: 24px;
    display: flex;
    align-items: flex-start;

    & > img {
      width: 176px;
      height: 226px;
      object-fit: cover;
      border-radius: 4px;
    }
    &-body {
      flex-grow: 1;
    }
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #251a31;
      margin-bottom: 16px;
    }
    .flex {
      gap: 16px;
      padding: 8px 12px;
      margin-bottom: 8px;
      justify-content: space-between;
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4b5563;
      }
      b {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #252a31;
        text-align: right;
      }
      &:nth-child(even) {
        background: #f3f4f6;
        border-radius: 4px;
      }
    }
    //Components
    &_edit {
      .p-4 {
        padding: 11px 1rem;
      }
    }
  }
  &__education {
    &_header {
      gap: 16px;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #251a31;
      }
      &_right {
        display: flex;
        gap: 12px;
      }
    }
  }
  &__settings {
    .customTabs {
      margin-top: 28px;
    }
    &--body {
      margin-top: 40px;
      h3.title {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 24px;
      }
    }
    &_form {
      width: 364px;
    }
    &_password {
      &_content {
        display: flex;
        gap: 54px;
      }
    }
  }
}

.detailPage {
  padding: 32px 0 120px;
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #251a31;
    margin-bottom: 32px;
  }
  .container {
    & > .flex {
      gap: 32px;
      align-items: flex-start;
    }
  }
  &__main {
    flex-grow: 1;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 1px 4px #e3ebfc, 0px 24px 48px rgba(230, 235, 245, 0.4);
    border-radius: 12px;

    .profile__info {
      margin-top: 20px;
      padding-bottom: 32px;
      border-bottom: 1px solid #f3f4f6;
      & > div {
        flex-grow: 1;
      }
    }
    section {
      margin-top: 24px;
      h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #251a31;
        margin-bottom: 32px;
      }
      .flex {
        gap: 12px;

        h3 {
          width: 240px;
          flex-shrink: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #4b5563;
        }
        p {
          width: 360px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #1f2a37;
        }
        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          text-decoration-line: underline;
          color: #1a56db;
        }
        .custom-button {
          display: flex;
          align-items: flex-start;
          flex-grow: 1;
          justify-content: end;
        }
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
      &:not(:last-child) {
        padding-bottom: 32px;
        border-bottom: 1px solid #f3f4f6;
      }
    }
  }
  &__sidebar {
    width: 384px;
    flex-shrink: 0;
    & > .flex {
      gap: 12px;
      flex-wrap: wrap;
    }
  }
  &__stepsbox {
    padding: 14px;
    filter: drop-shadow(0px 1px 4px #e3ebfc) drop-shadow(0px 24px 48px rgba(230, 235, 245, 0.4));
    border-radius: 12px;
    background-color: white;
    margin-bottom: 12px;
  }
  &__step {
    position: relative;
    & > .flex {
      gap: 14px;
      align-items: center;
    }
    &-number {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gray500;
      border-radius: 50%;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #6b7280;
    }
    &:not(:last-child) {
      padding-bottom: 32px;
      &:after {
        position: absolute;
        top: 0;
        left: 17px;
        width: 2px;
        height: 100%;
        background-color: #e5e7eb;
        content: "";
        z-index: -1;
      }
    }
    .comment {
      margin-left: 51px;
      p {
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
      .flex {
        gap: 8px;
        button {
          border-radius: 40px;
        }
      }
    }
    &-done {
      .detailPage__step-number {
        background: #1a56db;
        svg {
          width: 16px;
          height: 16px;
          path {
            fill: white;
          }
        }
      }
      &::after {
        background-color: #1a56db !important;
      }
      h3 {
        color: $gray700;
      }
    }
    &-current {
      .detailPage__step-number {
        background: #251a31;
        svg {
          width: 16px;
          height: 16px;
          path {
            fill: white;
          }
        }
      }
      &::after {
        background-color: #251a31 !important;
      }
      h3 {
        color: $black;
      }
    }
    &-error {
      .detailPage__step-number {
        background: #e01d1d;
        svg {
          width: 16px;
          height: 16px;
          path {
            fill: white;
          }
        }
      }
      &::after {
        background-color: #e01d1d !important;
      }
      h3 {
        color: $black;
      }
    }
  }
}

.service {
  background-color: #fafcff;
  .container {
    padding: 40px 0 80px;
    & > h1 {
      font-size: 32px;
      font-weight: 700;
      color: #251a31;
      line-height: 40px;
      margin-bottom: 40px;
    }
    & > .flex {
      align-items: flex-start;
    }
  }
  &__sidebar {
    width: 300px;
    flex-shrink: 0;
    margin-right: 16px;
    // padding-right: 16px;
    padding-bottom: 180px;
    box-sizing: content-box;
    border-right: 1px solid #e5e7eb;
    .divider {
      height: 1px;
      width: 100%;
      margin: 16px 0;
      background: #e5e7eb;
    }
    .sidebarBtn {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__body {
    flex-grow: 1;
  }
  &__list {
    gap: 24px;
    display: flex;
    flex-wrap: wrap;
  }
  &__action {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;
  }
  &__item {
    cursor: pointer;
    gap: 24px;
    width: 464px;
    // height: 168px;
    display: flex;
    align-items: flex-start;
    padding: 24px;
    position: relative;
    overflow: hidden;
    background: white;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
    box-shadow: 0px 1px 4px #e3ebfc, 0px 24px 48px rgba(230, 235, 245, 0.4);
    & > div {
      height: 100%;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
    }
    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #251a31;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.12px;
      color: #6b7280;
      margin-top: 8px;
    }
    h4 {
      margin-top: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
    &_higher_education {
      color: $blue700;
    }
    &_professional_education {
      color: $pink700;
    }
    &_secondary_special_education {
      color: $green700;
    }
    &_other {
      color: $purple700;
    }
    &:nth-child(1) svg {
      background: #f4e7fa;
    }
    &:nth-child(2) svg {
      background: #ffe9ef;
    }
    &:nth-child(3) svg {
      background: #fff3e0;
    }
    &:nth-child(4) svg {
      background: #e3f4ff;
    }
    .wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(1.5px);

      h2 {
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        padding: 4px 8px;
        border-radius: 4px;
        background: rgba(45, 45, 45, 0.5);
      }
    }
    &:hover {
      box-shadow: 0px 12px 32px rgba(11, 31, 51, 0.18);
    }
  }
}

.serviceID {
  &__navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: $black;
    }
  }
  &__body {
    gap: 32px;
    display: flex;
    align-items: flex-start;
  }
  &__main {
    flex-grow: 1;
    & > section {
      margin-top: 32px;
      padding: 24px;
      border-radius: 8px;
      background: #ffffff;
      box-shadow: 0px 1px 4px #e3ebfc, 0px 24px 48px rgba(230, 235, 245, 0.4);
      & > h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #251a31;
        padding-bottom: 24px;
        border-bottom: 1px solid #f3f4f6;
        margin-bottom: 16px;
      }
    }
    & > .serviceID__status {
      display: none;
    }
  }
  &__right {
    margin-top: 32px;
    width: 384px;
    flex-shrink: 0;

    position: sticky;
    top: 24px;
  }
  &__information {
    &-detail {
      padding: 12px;
      border-radius: 4px;
      border: 1px solid #f3f4f6;
      h3 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.12px;
        color: #6b7280;
        margin-bottom: 8px;
      }
      ul {
        margin-left: 24px;
        li {
          list-style-type: disc;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          letter-spacing: -0.12px;

          /* gray/900 */

          color: #111928;
        }
      }
    }
    .flex {
      gap: 16px;
      margin-bottom: 16px;
      & > div {
        flex-grow: 1;
        padding: 12px;
        border-radius: 4px;
        border: 1px solid #f3f4f6;
        h3 {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.12px;
          color: #6b7280;
          margin-bottom: 8px;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          letter-spacing: -0.12px;

          /* gray/900 */

          color: #111928;
        }
        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          letter-spacing: -0.12px;

          /* blue/700 */

          color: #1a56db;
        }
      }
    }
  }
  &__mobile {
    display: none;
  }
  &__video {
    &-video {
      width: 100%;
      height: 429px;
      background: #99b1e6;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  &__iframe {
    width: 100%;
    height: 430px;
    border-radius: 8px;
  }
  &__steps {
    .flex {
      gap: 32px;
      flex-direction: column;
      .step {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        & > span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          color: #1a56db;
          border-radius: 50%;
          flex-shrink: 0;
          border: 1px solid #1a56db;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
        }
        h3 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          /* gray/900 */

          color: #111928;
          margin-bottom: 4px;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* or 150% */

          letter-spacing: -0.12px;

          /* gray/600 */

          color: #4b5563;
        }
      }
    }
  }
  &__document {
    gap: 12px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .flex {
      gap: 10px;
      align-items: center;
      div {
        h3 {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          /* identical to box height, or 133% */

          /* black */

          color: #251a31;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          display: flex;
          align-items: flex-end;
          letter-spacing: -0.12px;

          /* gray/500 */

          color: #6b7280;

          opacity: 0.8;
        }
      }
    }

    padding: 12px 0;
    border-top: 1px solid #f3f4f6;
    &:first-child {
      border: none;
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__status {
    background: #ffffff;
    box-shadow: 0px 1px 4px #e3ebfc, 0px 24px 48px rgba(230, 235, 245, 0.4);
    border-radius: 8px;
    padding: 24px;
    .flex {
      justify-content: space-between;
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        /* gray/600 */

        color: #4b5563;
      }
      h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        text-align: right;

        /* black */

        color: #251a31;
      }
      &:first-child {
        margin-bottom: 12px;
      }
    }
    .custom-button {
      margin-top: 20px;
      padding-top: 24px;
      border-top: 1px solid #f3f4f6;
      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 920px) {
  .profile {
    .container {
      & > .flex {
        display: block;
      }
    }
    &__sidebar {
      display: none;
    }
    &__navmobile {
      display: block;
    }
  }

  .serviceID {
    &__body {
      flex-direction: column;
    }
    &__right {
      display: none;
    }
    &__mobile {
      margin-top: 24px;
      display: block;
    }
    &__main {
      & > .serviceID__status {
        display: block;
        position: sticky;
        bottom: 8px;
        margin: 0 -16px;
      }
    }
  }

  .detailPage {
    .container {
      & > .flex {
        flex-direction: column-reverse;
      }
    }
    &__sidebar {
      width: 100%;
    }
    &__main {
      section {
        .flex {
          h3,
          p {
            width: auto;
          }
          & > div {
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .diplomCard__body {
    .flex {
      flex-wrap: wrap;
      p {
        display: none;
      }
      h1,
      a {
        width: auto;
      }
    }
  }
  .service {
    .container {
      & > h1 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
      }
      & > .flex {
        display: block;
      }
    }
    &__sidebar {
      display: none;
    }
    &__list {
      gap: 16px;
      flex-direction: column;
    }
    &__item {
      height: auto;
      width: 100%;
      justify-content: space-between;
      & > div {
        flex-grow: 1;
      }
      h2 {
        font-size: 14px;
        line-height: 24px;
      }
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .customBroudCamp {
    width: 100%;
    border: none;
    background: none;
    overflow-x: scroll;
    padding: 12px 0;
    margin-bottom: 12px;
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      white-space: nowrap;
    }
  }
  .serviceID {
    &__navbar {
      h1 {
        font-size: 24px;
        line-height: 32px;
      }
      .custom-button {
        display: none;
      }
    }
    &__main > section {
      margin-top: 24px;
    }
    &__information {
      .flex {
        flex-direction: column;
      }
    }
  }
  .profile {
    &__info {
      h2 {
        font-size: 20px;
        line-height: 28px;
      }
      .flex {
        span,
        b {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
  .detailPage {
    &__title {
      font-size: 24px;
      line-height: 32px;
    }
    &__main {
      .applicationCard__header {
        gap: 16px;
        flex-direction: column;
        align-items: flex-start;
      }
      section {
        h2 {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
        }
        .flex {
          gap: 8px;
          flex-direction: column;
          h3,
          p {
            font-size: 14px;
            line-height: 24px;
          }

          .custom-button {
            display: block;
          }
          a {
            text-align: left;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .profile {
    &__info {
      align-items: center;
      flex-direction: column;

      &-body {
        width: 100%;
      }
    }
    &__education {
      &_header {
        .flex {
          flex-wrap: wrap;
        }
      }
    }
  }
  .home__notifications_body .rightLongArrow {
    display: none;
  }
  .serviceID {
    &__video-video {
      height: 200px;
      svg {
        width: 64px;
        height: 64px;
      }
    }
    &__iframe {
      height: 300px;
    }
  }
}
