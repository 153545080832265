.custom-upload {
  &-croppedImg {
    width: 176px;
    height: 226px;
    position: relative;
    transition: $transition;
    img {
      width: 176px;
      height: 226px;
      border-radius: 4px;
    }
    &-wrap {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      transition: $transition;
      opacity: 0;
      border-radius: 4px;

      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      div {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: $blue50;
      }
    }
    &:hover .custom-upload-croppedImg-wrap {
      opacity: 1;
    }
  }
  &-card {
    width: 176px;
    height: 226px;
    background-color: $gray100;
    border: 1px dashed $gray500;
    border-radius: 4px;
    transition: $transition;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    &:hover {
      border-color: $blue700;
    }

    svg {
      margin-top: 75px;
    }

    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $blue700;
    }

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $gray600;
      text-align: center;
    }
  }
  &-modal {
    & > div {
      max-width: 802px !important;
      // height: 90vh;
      // overflow-y: auto;

      /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
    &-header {
      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: $black;
      }
    }
    &-body {
      padding: 16px 24px 24px;
      h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
    &-footer {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 32px;
    }
  }
  &-info {
    display: flex;
    gap: 12px;
    &-left {
      width: 4px;
      flex-shrink: 0;
      background-color: $yellow300;
      border-radius: 4px;
      height: 52px;
    }
    p {
      font-size: 14px;
    }
  }
  &-line {
    width: 100%;
    border-top: 1px solid $gray100;
    margin: 16px 0;
  }
  &-images {
    display: flex;
    gap: 32px;
    margin-top: 24px;
    p {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-top: 8px;

      font-weight: 500;
      font-size: 14px;
    }
  }
  &-crop {
    &-content {
      display: flex;
      gap: 24px;
      margin-top: 66px;
      &-left {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;

        position: relative;
      }
      &-right {
        flex-shrink: 0;
        width: 211px;
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          &:first-child {
            margin-bottom: 194px;
          }
        }
      }
    }
    &-zoom {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 13px;
      margin-top: 14px;
      &-range {
        width: 217px;
        input {
          background: $primary600;
          &::-moz-range-thumb {
            background-color: $white;
            -moz-appearance: none;
            transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: 1px solid $gray200;
            background: $white;
            border-radius: 50%;
            width: 22px;
            height: 22px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
            transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
        }
        &:hover input[type="range"]::-webkit-slider-thumb {
          box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
          border-radius: 50%;
        }

        &:hover input[type="range"]::-moz-range-thumb {
          box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
        }
      }

      .button {
        cursor: pointer;
      }
    }
    &-container {
      width: 280px;
      height: 360px;
      position: relative !important;
      overflow: visible;
      user-select: none;
      touch-action: none;
      cursor: move;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
    &-area {
      width: 280px !important;
      height: 360px !important;
      &::before {
        content: " ";
        width: 280px !important;
        height: 360px !important;
        position: absolute;
        border: none !important;
        left: 0 !important;
        right: 0 !important;
        background-image: url("../../../assets//images/uploadWrap.png");
      }
      &::after {
        content: " ";
        width: 280px !important;
        height: 360px !important;
        position: absolute;
        border: none !important;
        top: 0 !important;
        bottom: 0 !important;
        background-image: url("../../../assets//images/uploadLine.png");
      }
    }
  }
}

@media (max-width: 776px) {
  .custom-upload {
    &-images {
      overflow-x: scroll;
      & > div {
        width: 172px;
        flex-shrink: 0;
      }
      &::-webkit-scrollbar {
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #ffffff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b8b8b8;
        border-radius: 4px;
        border: 2px solid #ffffff;
      }
    }
  }
}

@media (max-width: 600px) {
  .custom-upload {
    &-modal {
      & > div {
        padding: 0 8px !important;
        height: 100vh !important;
      }
      &-footer {
        flex-direction: column;
        button {
          width: 100%;
        }
      }
    }
    &-crop {
      &-content {
        margin-top: 0;
        align-items: center;
        flex-direction: column;

        &-right {
          width: 270px;

          p {
            &:first-child {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
