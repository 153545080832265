.applicationCard {
  margin-top: 24px;
  padding: 16px 24px;
  background: $white;
  border-radius: 12px;
  box-shadow: 0px 1px 4px $shadow, 0px 24px 48px rgba(230, 235, 245, 0.4);

  &__header {
    gap: 8px;
    flex-wrap: wrap;
    padding-bottom: 16px;
    border-bottom: 1px solid $gray100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .flex {
      gap: 12px;
    }
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $black;
    }
    h4 {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $gray500;
      b {
        font-weight: 700;
        color: $black;
      }
      &:first-child {
        padding-right: 13px;
        border-right: 1px solid $gray500;
      }
    }
  }
  &__body {
    gap: 8px;
    flex-wrap: wrap-reverse;
    margin: 12px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .left {
      h2 {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $black;
      }
      p {
        margin-top: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $gray500;
      }
    }
  }
  &__footer {
    flex-wrap: wrap;
    gap: 12px;
    padding-top: 16px;
    border-top: 1px solid $gray100;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.diplomCard {
  margin-top: 24px;
  padding: 16px 24px;
  background-color: $white;
  box-shadow: 0px 1px 4px $shadow, 0px 24px 48px rgba(230, 235, 245, 0.4);
  border-radius: 8px;
  &__header {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 4px;
    padding-bottom: 16px;
    justify-content: space-between;
    border-bottom: 1px solid $gray100;
    .flex {
      gap: 8px;
    }
    span {
      gap: 4px;
      display: flex;
      align-items: center;
      margin-right: 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $gray400;
    }
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $black;
    }
  }
  &__body {
    .flex {
      gap: 8px;
      margin-top: 12px;
      h2 {
        flex-shrink: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $gray500;
      }
      p {
        flex-grow: 1;
        margin-top: 16px;
        border-top: 1px dashed $gray200;
      }
      h1 {
        width: 520px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $black;
      }
      a {
        width: 520px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: underline;
        color: $blue700;
      }
    }
  }
  &__editBtn {
    height: 24px;
    padding: 4px;
    background: $blue50;
    border-radius: 4px;
  }
  &__deleteBtn {
    height: 24px;
    padding: 4px;
    background: $red50;
    border-radius: 4px;
  }
}

.customTabs {
  gap: 32px;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  border-bottom: 1px solid $gray200;

  .flex {
    flex: none;
    gap: 6px;
    cursor: pointer;
    color: $gray500;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding: 12px;
    align-items: center;
    border-bottom: 2px solid transparent;

    svg {
      flex-shrink: 0;
    }
  }
  .active {
    color: $blue700;
    border-bottom: 2px solid $blue700;
    svg path {
      fill: $blue700;
    }
  }
  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    border-radius: 4px;
    border: 2px solid #ffffff;
  }
}

.notificationToggle {
  width: 696px;
  padding: 24px;
  margin-top: 24px;
  border-radius: 8px;
  border: 1px solid $gray200;
  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 16px;
  }
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    margin-bottom: 4px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $gray500;
    width: 90%;
  }
}

.sessionCard {
  margin-top: 24px;
  padding: 16px 24px;
  background: $white;
  border-radius: 12px;
  box-shadow: 0px 1px 4px $shadow, 0px 24px 48px rgba(230, 235, 245, 0.4);

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 16px;
    justify-content: space-between;
    border-bottom: 1px solid $gray100;

    h2 {
      color: $black;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    .flex {
      gap: 16px;
    }
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $gray500;
      b {
        font-weight: 700;
        color: $black;
      }
      &:nth-child(1) {
        padding-right: 12px;
        border-right: 1px solid $gray500;
      }
    }
  }
  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $black;
  }
  .flex {
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 8px;
    justify-content: space-between;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $gray500;
    }
  }
  &_list {
    .sessionCard {
      &__header {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 20px;
      }
      &__wrap {
        border-bottom: 1px solid $gray100;
        padding-bottom: 24px;
        margin-bottom: 24px;
        &:last-child {
          border: none;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

.infocard {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  &-left {
    width: 50%;
    display: flex;
    align-items: flex-end;
    gap: 8px;
    h2 {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $gray500;
    }
    p {
      flex-grow: 1;
      border-bottom: 1px dashed $gray200;
      margin-bottom: 6px;
    }
  }
  h3 {
    width: 50%;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $black;
    overflow-wrap: anywhere;
  }
}

.resultcard {
  &-content {
    &-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: $gray900;
      text-align: center;
      letter-spacing: -0.01em;
    }
    &-rate {
      display: flex;
      justify-content: center;
      gap: 35px;
      margin: 24px 0;
      &-title {
        height: 32px;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: $blue700;
        text-align: center;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
      }
      svg {
        cursor: pointer;
      }
    }
  }
  & > div {
    max-width: 565px !important;
  }
  .login-header {
    text-align: center;
    p {
      width: calc(100% + 52px);
      &:last-child {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: $gray900;
        margin-top: 12px;
      }
    }
  }
}

.notebook_cert {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid $gray500;

  color: $gray500;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  & > svg {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 8px;
  }
  &-active {
    color: $green700;
    border-color: $green700;

    & > svg {
      path {
        fill: $green700;
      }
    }
  }
}

.messageItem {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--blue-700, #1a56db);
  background: var(--primary-50, #ebf5ff);

  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &__header {
    display: flex;
    gap: 36px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;

    h2 {
      color: var(--primary-700, #1a56db);
      /* text-base/font-semibold */
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      &:last-child {
        flex-shrink: 0;
      }
    }
  }
  p {
    color: var(--primary-700, #1a56db);
    /* text-base/font-normal */
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.12px;
  }
  &__error {
    border-color: transparent;
    background-color: #fde8e8;
    h2,
    p {
      color: #c81e1e;
    }
  }
  &__success {
    border-color: #046c4e;
    background-color: #f3faf7;
    h2,
    p {
      color: #046c4e;
    }
  }
}

@media (max-width: 776px) {
  .notificationToggle {
    width: 100%;
    p {
      width: 100%;
    }
  }
  .messageItem {
    &__header {
      flex-wrap: wrap;
      gap: 4px;
    }
  }
}

@media (max-width: 540px) {
  .applicationCard {
    &__header h4 b {
      display: block;
    }
  }
  .loader-component {
    width: 100% !important;
    height: 36px !important;
    rect {
      height: 36px !important;
    }
  }
  .loader-home {
    width: 100%;
  }
}
