// Footer
.footer {
  padding: 64px 0 24px;
  background: #251a31;
  .logo {
    justify-content: flex-start;
    span {
      color: #ffffff;
      letter-spacing: 1.1px;
      width: 309px;
    }
  }

  &__nav {
    gap: 72px;
    display: flex;
    padding-bottom: 48px;
    align-items: flex-start;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-bottom: 1px solid #374151;
  }
  &__info {
    .info {
      color: white;
      margin-top: 12px;
      opacity: 1;
    }
    .flex {
      gap: 16px;
      justify-content: flex-start;
    }
  }
  &__links {
    width: 250px;
    flex-shrink: 0;
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;
      margin-bottom: 24px;
    }
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #b2b2b2;
      margin-top: 12px;
      display: block;
    }
  }
  &__socials {
    width: 250px;
    flex-shrink: 0;
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;
      margin-bottom: 24px;
    }
    .social {
      display: flex;
      align-items: center;
      margin-top: 12px;
      gap: 8px;
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        display: block;
      }
    }
  }
  &__contact {
    width: 260px;
    flex-shrink: 0;
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;
      margin-bottom: 24px;
    }
    .contact {
      display: flex;
      align-items: center;
      margin-top: 12px;
      gap: 8px;
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        display: block;
      }
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .container > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin-top: 16px;
    text-align: end;
    span {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
  }
}

@media (max-width: 540px) {
  .footer {
    &__info {
      order: 4;
    }
    .container > p {
      text-align: start;
    }
    &__nav {
      gap: 24px;
      justify-content: flex-end;
    }
  }
}
