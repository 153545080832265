*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

html,
body {
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
  // background-color: theme("colors.primary.600");
}

input {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1280px;
}

.main-content {
  min-height: calc(100vh - 273px);
  background-color: #fafcff;
  position: relative;
}

// Import Slick styles
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "./variables/";
@import "./flowbite/";
@import "./home/";
@import "./common/";
@import "./form/";
@import "./admissions/";
@import "./login/";
@import "./profile/";
@import "./layout/";
@import "./status/";
