.customDropDown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: 0.3s;

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $gray800;

  border-radius: 4px;
  border: 1px solid transparent;
  outline: 2px solid transparent;
  svg {
    width: 24px;
    height: 24px;
    transition: 0.3s;
    path {
      transition: 0.3s;
    }
  }
  color: $white;
  background-color: $glass;
  &:hover {
    background-color: $glass_hover;
  }
  &:focus {
    background-color: $glass_active;
  }
  & > .custom-button {
    button {
      color: white;
    }
    svg {
      &:last-child {
        path {
          fill: white;
        }
      }
    }
  }
  &__menu {
    z-index: -1;
    opacity: 0;
    padding: 8px;
    bottom: -140px;
    background: #ffffff;
    position: absolute;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
    left: 50%;
    transform: translateX(-50%);
    button {
      width: 100%;
      justify-content: flex-start;
    }
  }
  &__active {
    z-index: 5;
    opacity: 1;
    bottom: -110px;
  }
  &__profile {
    cursor: pointer;
    background-color: transparent;
    & > .flex {
      gap: 8px;
      align-items: center;
      div {
        height: 48px;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: linear-gradient(80.91deg, #e13e3b 0%, #e87e09 100%);

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
    .customDropDown__menu {
      bottom: -400px;
      width: 300px;
      border-radius: 4px;
      padding: 16px;
      right: 0;
      left: initial;
      transform: initial;
    }
    .customDropDown__active {
      bottom: -360px;
    }
    &-menu {
      .menu-profile {
        gap: 12px;
        display: flex;
        align-items: center;
        padding: 8px 0 12px;
        margin-bottom: 12px;
        justify-content: space-between;
        border-bottom: 1px solid #f3f4f6;
        & > div:first-child {
          flex-shrink: 0;
          height: 48px;
          width: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: linear-gradient(80.91deg, #e13e3b 0%, #e87e09 100%);

          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
        .flex {
          flex-grow: 1;
          flex-direction: column;
          h2 {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #251a31;
          }
          p {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #6b7280;
          }
        }
      }
      .custom-button .menu {
        svg path {
          fill: #6b7280;
        }
        &:hover {
          svg path {
            fill: #1a56db;
          }
        }
      }
      .custom-button .red {
        border: none;
        border-top: 1px solid #f3f4f6;
      }
    }
  }
}
.navbar {
  width: 100%;
  background-color: white;

  position: absolute;
  top: 0;
  left: 0;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
  }
  &-logo {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $gray900;

    display: flex;
    align-items: center;
    gap: 12px;
  }
  &-ghost {
    // margin-top: 100px;
    background-color: transparent;
    .navbar {
      &-logo {
        color: $white;
      }
    }
    .yordamBtn {
      color: white;
      svg path {
        fill: white;
      }
    }
  }
  &-profile {
    position: relative;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    .loginBtn {
      border-radius: 4px;
      background-color: #e5e7eb;
      .custom-button .glass {
        color: #251a31;
        svg path {
          fill: #251a31;
        }
      }
    }
    .customDropDown {
      border-color: $gray200;
      & > .custom-button {
        button {
          color: black;
          svg {
            &:last-child {
              path {
                fill: black;
              }
            }
          }
        }
      }
      &__menu {
        background-color: #ffffff;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05);
      }
      &__profile {
        border-color: transparent;
        & > .flex {
          svg path {
            fill: #251a31;
          }
        }
      }
    }
  }
  &-burger {
    display: none;
  }
  &__login {
    & > .custom-button {
      button {
        height: 100%;
      }
    }
  }
  &__mobile {
    position: fixed;
    background: white;
    top: 0;
    left: 101%;
    width: 101vw;
    height: 101vh;
    z-index: 3;
    display: block !important;
    padding: 24px !important;
    overflow-y: scroll;
    transition: 0.3s ease-in-out;

    &-top {
      gap: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > svg {
        flex-shrink: 0;
      }
      .navbar-logo {
        color: $blue700;
      }
      .yordamBtn {
        display: flex !important;
        color: black !important;

        svg path {
          fill: black;
        }
      }
    }
    &-nav {
      margin-top: 24px;
      .custom-button {
        margin-top: 8px;
        button {
          width: 100%;
          justify-content: flex-start;
          padding: 8px 0;
        }
        svg path {
          fill: #6b7280;
        }
        &:nth-child(2) {
          padding: 8px 0;
          border-top: 1px solid $gray200;
          border-bottom: 1px solid $gray200;
        }
      }
    }
    .customDropDown__menu {
      position: initial;
      transform: none;
      padding: 0;
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid $gray200;
      border-radius: initial;
      box-shadow: none;
      .custom-button button {
        padding: 8px 0;
      }
    }
    &-active {
      left: 0;
    }
  }
}

@media (max-width: 754px) {
  .navbar {
    .yordamBtn,
    .customDropDown {
      display: none;
    }
    &-burger {
      display: block;
    }
    .customDropDown__profile {
      display: block;
    }
  }
}
