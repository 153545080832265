.custom-input {
  &-top {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    svg {
      cursor: pointer;
    }
  }
  &-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $gray900;
  }
  &-help {
    border: none;
    background: none;
    padding: none !important;
  }
  .input {
    color: $gray900;
  }
  .mt-2 {
    margin-top: 0;
  }
  .react-date-picker {
    width: 100%;
    &__wrapper {
      width: 100%;
      border: 1px solid $gray300;
      border-radius: 4px;
      background: $gray50;
      padding: 9.5px 16px !important;
      input {
        padding: 0;
        background: $gray50;

        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
    }
    &__inputGroup {
      padding: 0;
      &__divider {
        padding: 0;
      }
    }
    &__clear-button {
      display: none;
    }
    &__calendar-button {
      svg {
        stroke: $gray500;
      }
    }
    .react-calendar__tile--active {
      background-color: $primary600;
    }
  }
  &-upload {
    select:not([size]),
    input {
      padding: 0 !important;
    }
  }

  //Size
  &-lg {
    .react-date-picker__wrapper {
      padding: 14.5px 16px !important;
    }
  }
}
.password-input {
  position: relative;
  .eyeIcon {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 50px;
  }
}
