.login {
  & > div {
    padding: 0;
    max-width: 492px;
    position: static !important;
    & > div {
      position: static !important;
    }
  }
  &-header {
    padding: 2rem 0 1.5rem;
    margin: 0 2rem;
    gap: 20px;

    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: $gray900;
    }
  }
  &-body {
    padding: 1.5rem 2rem 2rem;
  }
  &-main {
    &-forget {
      color: $gray500;
      transition: $transition;
      text-align: right;
      margin-bottom: 20px;
      cursor: pointer;

      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      &:hover {
        color: $primary600;
      }
    }
    &-other {
      border: 1px solid $gray300;
      margin: 43px 0;
      position: relative;
      p {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #111928;
        text-align: center;

        position: absolute;
        top: -12px;
        left: 0px;

        display: flex;
        justify-content: center;
        span {
          width: 224px;
          background-color: white;
        }
      }
    }
  }
  &-signup {
    &-inputs {
      display: flex;
      gap: 16px;
      .custom-input {
        &:first-child {
          width: 140px;
        }
      }
    }
    &-footer {
      margin-top: 20px;
      a {
        color: $blue700;
        text-decoration: underline;
      }
    }
    &-drower {
      p {
        margin-bottom: 32px;
      }
    }
    .custom-input {
      select:not([size]),
      input {
        padding: 16px;
        font-size: 16px;
        line-height: 22px;
        border-radius: 4px;
        background-color: $gray50;
      }
    }
  }
  &-signin {
    &-reset {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $primary600;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }
  &-check {
    &-info {
      color: $gray500;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
      span {
        color: $black;
      }
    }
    &-resend {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $blue700;
      margin: -12px 0 20px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
  &-password {
    &-rules {
      margin-bottom: 16px;
      h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $black;
        margin-bottom: 4px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $gray500;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .active {
        color: $green700;
      }
    }
  }
  &-finished {
    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: $gray900;
      text-align: center;
      margin: 24px 0 12px;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: $gray900;
      text-align: center;
      margin-bottom: 32px;
    }
    &-buttons {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      .custom-button {
        width: 50%;
        button {
          width: 100%;
        }
      }
      a {
        width: 50%;
        .custom-button {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .login {
    height: 100vh;
    & > div {
      height: 100vh;
      padding: 0 16px;
    }
    &-header {
      h3 {
        font-size: 24px;
        line-height: 32px;
      }
    }
    &-body {
      .custom-radio-wrap {
        flex-direction: column;
        label {
          width: 100%;
        }
      }
    }
  }
}
