.application {
  padding: 56px 0 120px;
  &-steps {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  &-content {
    width: 800px;
    margin: 0 auto;
  }
  &-box {
    background: $white;
    box-shadow: $car_shadow;
    border-radius: 12px;
    padding: 24px;
    &-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: $black;
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid $gray100;

      display: flex;
      justify-content: space-between;
      align-items: center;
      .right {
        display: flex;
        align-items: center;
        gap: 24px;
      }
      button span {
        color: $gray400;
      }
      .active span {
        color: $gray900;
      }
    }
    &-subtitle {
      width: 100%;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px;
    }
    &-form {
      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 12px;
      align-items: flex-start;
      .full {
        width: 100%;
      }
      .half {
        width: calc(50% - 12px);
      }
      .custom-input {
        select:not([size]),
        input {
          padding: 12px 16px;
          font-size: 16px;
          line-height: 22px;
          border-radius: 4px;
        }
        .react-date-picker__wrapper {
          input {
            padding: 0;
            border: none;
          }
        }
      }
    }
    &-tiny {
      display: flex;
      column-gap: 12px;
      .first {
        width: 109px;
      }
      .second {
        flex-grow: 1;
      }
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
    }
    &-fish {
      width: 552px;
    }
    .custom-input {
      select:not([size]),
      input {
        padding: 12px 16px;
        font-size: 16px;
        line-height: 22px;
        border-radius: 4px;
      }
    }
  }
  &-line {
    width: 100%;
    border-top: 1px solid $gray100;
    margin-bottom: 12px;
  }
  &-unchecked {
    display: flex;
    justify-content: flex-end;
    margin-top: -17px;
    &-left {
      width: 430px;
      padding-bottom: 40px;
      h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $gray900;
        margin-bottom: 12px;
      }
      h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $gray500;
        margin: 57px 0 12px;
      }
      ul {
        padding-left: 18px;
        margin-bottom: 8px;
        li {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: $gray900;
          list-style-type: disc;
        }
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $gray500;
        letter-spacing: -0.12px;
        margin-bottom: 24px;
        span {
          color: $gray900;
          font-weight: 600;
        }
      }
    }
    &-right {
      width: 161px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &-accordion {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    div {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray200;
      border-radius: 50%;
    }
  }
  &-certificate {
    background: $white;
    box-shadow: 0px 1px 4px #e3ebfc, 0px 24px 48px rgba(230, 235, 245, 0.4);
    border-radius: 8px;
    padding: 16px 24px;
    border: 2px solid transparent;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gray100;
      padding-bottom: 17px;
      margin-bottom: 16px;

      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $black;
    }
    &-button {
      font-size: 14px;
      line-height: 14px;
      color: $gray900;

      display: flex;
      align-items: center;
      gap: 8px;
      transition: $transition;
      cursor: pointer;
      .radio {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $gray50;
        border: 0.5px solid $gray300;
        box-sizing: border-box;
      }
      &:hover {
        .radio {
          border: 3.5px solid $primary600;
          background-color: $white;
        }
      }
    }
    &-active {
      border-color: $blue700;
      box-shadow: none;
      .radio {
        border: 3.5px solid $primary600;
      }
    }
    &-tabs {
      gap: 16px;
      div {
        &:nth-child(2) {
          & > div {
            padding: 0;
          }
        }
      }
    }
  }
  .full {
    width: 100%;
  }
  .half {
    width: calc(50% - 12px);
  }
}
.youtubePLayer {
  position: relative;
  width: 640px;
  height: 400px;
  iframe {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
  .custom-button {
    top: -36px;
    right: -36px;
    position: absolute;
    button {
      padding: 6px;
    }
  }
}
.interview {
  &__percent {
    column-gap: 36px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    align-items: flex-end;
    border-top: 1px solid #1a56db;

    &-box {
      height: 24px;
      flex-grow: 1;
      min-width: 240px;
      position: relative;
      & > div {
        left: 0;
        height: 6px;
        bottom: 4px;
        width: 100%;
        position: absolute;
        border-radius: 4px;
        background: #e5e7eb;
        overflow: hidden;
        p {
          height: 100%;
          background-color: #ff8a4c;
        }
      }
      & > span {
        top: 0;
        right: 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        position: absolute;
        color: var(--gray-500, #6b7280);
      }
    }
  }
  &__semestr {
    width: 100%;
    &-title {
      padding: 8px 12px;
      background: var(--blue-50, #ebf5ff);

      color: var(--black, #251a31);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    .flex {
      margin-top: 0 !important;
      flex-wrap: wrap;
      row-gap: 4px !important;
      column-gap: 24px !important;
      padding: 8px 12px;
      justify-content: space-between;
      border: 1px solid var(--blue-100, #e1effe);
      &:not(:last-child) {
        border-bottom: none;
      }
      h3,
      h4 {
        width: fit-content !important;
        color: var(--black, #251a31);
        /* text-base/font-medium */
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      h4 {
        font-weight: 400;
        letter-spacing: -0.12px;
      }
    }
    .error {
      background: #fde8e8;
    }
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
}
.customResultIframe {
  iframe {
    width: 100%;
    height: 800px;
  }
  .mobile {
    display: none;
  }
}
.resultTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
  h2 {
    margin-bottom: 0 !important;
  }
  a {
    font-size: 14px;
    color: #1a56db;
  }
}

@media (max-width: 920px) {
  .application {
    &-steps {
      & > ol {
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
      li {
        display: flex;
        flex-grow: 1;
        margin-left: 0 !important;
        white-space: nowrap;
        position: relative;
        justify-content: center;
        span {
          z-index: 2;
          position: relative;
          background: white !important;
          &:last-child {
            display: none;
          }
        }

        &:not(:first-child)::after {
          content: "";
          width: 100%;
          height: 2px;
          position: absolute;
          top: 47%;
          left: -50%;
          z-index: 1;
          border-bottom: 1px dashed rgb(148, 148, 148);
        }
      }
    }
    &-content {
      width: 100%;
    }
    &-box {
      &-form {
        align-items: center;
        flex-direction: column;

        .half,
        .custom-radio {
          width: 100%;
        }
      }
      &-fish {
        width: 100%;
      }
    }
  }
}

@media (max-width: 786px) {
  .youtubePLayer {
    &__modal {
      height: 100vh;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
          width: 94%;
          .youtubePLayer {
            width: 100%;
            .custom-button {
              top: -48px;
              right: 0;
              position: absolute;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .application {
    &-box {
      &-title {
        gap: 16px;
        flex-wrap: wrap;
        .right {
          flex-wrap: wrap;
        }
      }
      &-footer {
        background: white;
        padding: 16px;
        border-radius: 4px;
        gap: 8px;
        bottom: 0;
        position: sticky;
        flex-direction: column;
        button {
          width: 100%;
        }
      }
    }
    &-unchecked-right {
      display: none;
    }
  }
  .customResultIframe {
    iframe {
      height: 500px;
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
}
