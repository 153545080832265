@import "./button/";
@import "./drower/";
@import "./cards/";

.custom-select {
  option {
    white-space: pre-wrap !important;
    max-width: 752px;
  }
}
